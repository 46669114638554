<template>
  <InputBase :class="{ disabled: isDisabled, tf_error: isError }">
    <input
      v-model="input"
      type="text"
      :class="extraClass"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :maxLength="maxLength"
      @focusout="(e) => $emit('focusout', e)"
      @focus="(e) => $emit('focus', e)"
    />
  </InputBase>
  <!-- ref="applyCount" -->
</template>

<script>
import InputBase from "@/_approval/components/common/input/InputBase";
import { getCanConvertNumber } from "@/utils/numberUtils";

export default {
  name: "InputNumber",
  components: {
    InputBase,
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
    extraClass: String,
  },
  computed: {
    input: {
      get() {
        // if (applyCount) {
        //   applyCount = applyCount.replace(/[^0-9]/g, '');
        //   this.applyCount = applyCount;
        // }
        // this.assetApply.applyCount = applyCount;

        const validText = this.getValidText(this.value);

        if (validText !== this.value) {
          this.$emit("update:value", validText);
        }

        if (this.maxLength && this.value && this.value.length > this.maxLength) {
          this.$emit("update:value", this.value.slice(0, this.maxLength));
        }

        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    getValidText(strOrg) {
      if (!strOrg) return "";

      strOrg = strOrg.trim();

      // 그냥 다 숫자면 그대로 리턴.
      if (getCanConvertNumber(strOrg)) {
        // return addCommaForMoney(strOrg);
        return strOrg;
      }

      let ret = "";

      let char;

      for (var i = 0; i < strOrg.length; ++i) {
        char = strOrg.charAt(i);

        if (!getCanConvertNumber(char)) continue;

        ret += char;
      }

      if (!ret) return "";

      // const nRet = Number.parseInt(ret, 10);

      // return addCommaForMoney(nRet);
      return ret;
    },
  },
};
</script>

<style></style>
