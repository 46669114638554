export default class EmailModel {
  constructor(name, domain) {
    this.name = name || "";
    this.domain = domain || "";
  }

  setData(name, domain) {
    this.name = name || "";
    this.domain = domain || "";
  }

  setDataByString(strTextFull) {
    if (typeof strTextFull != String && strTextFull.name) {
      this.name = strTextFull.name;
      this.domain = strTextFull.domain;
      return true;
    }
    const arr = strTextFull.split("@");
    if (arr.length !== 2) return false;

    this.name = arr[0];
    this.domain = arr[1];

    return true;
  }

  getIsValid() {
    if (!this.name.trim()) return false;
    if (!this.domain.trim()) return false;

    return true;
  }

  getTextFull() {
    if (!this.getIsValid()) return "";

    const ret = `${this.name}@${this.domain}`;
    return ret;
  }
}
