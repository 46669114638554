import EmailModel from "@/_approval/models/EmailModel";

export default class WithHoldingTaxModel {
  constructor() {
    this.businessPlaces = ""; // 사업장코드
    this.businessPlacesName = ""; // 사업장명
    this.email = new EmailModel(); // 이메일
    this.subdivisionCode = ""; // 하위분류코드
    this.subdivisionName = ""; // 하위분류명
    this.taxBurdenYn = "Y"; // 세액회사부담여부
    // this.taxCode = ''; // 세금유형
    this.voucherCid = ""; // 전표 번호
    this.withholdingTaxId = ""; // 원천세 id
    this.withholdingTaxCode = ""; // 세금유형코드
    this.withholdingTaxName = ""; // 세금유형명
    this.withholdingTaxRate = ""; // 세금비율
    this.withholdingTaxVoucherType = 1;
  }

  setData(obj) {
    const {
      businessPlaces,
      businessPlacesName,
      email,
      subdivisionCode,
      subdivisionName,
      taxBurdenYn,
      voucherCid,
      withholdingTaxId,
      withholdingTaxCode,
      withholdingTaxName,
      withholdingTaxRate,
      withholdingTaxVoucherType,
    } = obj;

    if (businessPlaces) this.businessPlaces = businessPlaces;
    if (businessPlacesName) this.businessPlacesName = businessPlacesName;
    if (email) this.email.setDataByString(email);
    if (subdivisionCode) this.subdivisionCode = subdivisionCode;
    if (subdivisionName) this.subdivisionName = subdivisionName;
    if (taxBurdenYn) this.taxBurdenYn = taxBurdenYn;
    if (voucherCid) this.voucherCid = voucherCid;
    if (withholdingTaxId) this.withholdingTaxId = withholdingTaxId;
    if (withholdingTaxCode) this.withholdingTaxCode = withholdingTaxCode;
    if (withholdingTaxName) this.withholdingTaxName = withholdingTaxName;
    if (withholdingTaxRate) this.withholdingTaxRate = withholdingTaxRate;
    if (withholdingTaxVoucherType) this.withholdingTaxVoucherType = withholdingTaxVoucherType;
  }

  getData() {
    let obj = {};

    obj.businessPlaces = this.businessPlaces;
    obj.businessPlacesName = this.businessPlacesName;

    if (this.email.getIsValid()) {
      obj.email = this.email.getTextFull();
    }

    obj.subdivisionCode = this.subdivisionCode;
    obj.subdivisionName = this.subdivisionName;
    obj.taxBurdenYn = this.taxBurdenYn;
    obj.voucherCid = this.voucherCid;
    obj.withholdingTaxId = this.withholdingTaxId;
    obj.withholdingTaxCode = this.withholdingTaxCode;
    obj.withholdingTaxName = this.withholdingTaxName;
    obj.withholdingTaxRate = this.withholdingTaxRate;
    obj.withholdingTaxVoucherType = this.withholdingTaxVoucherType;

    return obj;
  }

  // getIsValid() {
  //   if( !this.name )  return false;
  //   if( !this.job )  return false;
  //   if( !this.mobile.getIsValid() )  return false;
  //   if( !this.email.getIsValid() )  return false;

  //   return true;
  // }

  // getAlertMessage() {
  //   if( !this.name )  return '담당자 이름을 확인해주세요.';
  //   if( !this.jobgrade )  return '담당자 직급를 확인해주세요.';
  //   if( !this.job )  return '담당자 직무를 확인해주세요.';
  //   if( !this.mobile.getIsValid() )  return '담당자 휴대전화를 확인해주세요.';
  //   if( !this.email.getIsValid() )  return '담당자 이메일을 확인해주세요.';

  //   return null;
  // }
}
