<template>
  <div
    ref="opt_comm"
    :class="['opt_comm', { disabled: isDisabled }, classList]"
    @click.prevent="openToggle"
  >
    <!-- 비활성일 때 disabled 클래스 추가 / 100px 너비 타입은 type_short 클래스 추가 / 읽음 표시 타입일 때 type_readcheck 클래스 추가 -->
    <strong class="screen_out">{{ optionDesc }}</strong
    ><!-- 내용에 따라 다르게 입력 -->
    <span class="screen_out">선택내용 : </span>
    <a ref="aria" href="javascript:void(0);" class="link_opt" aria-expanded="false"
      ><!-- 오픈시 true, 닫혔을 때 false -->
      {{ displayOptionName }}<span class="ico_account ico_opt" />
    </a>
    <div ref="box_opt" class="box_opt">
      <span class="screen_out">선택옵션</span>
      <ul class="list_opt" role="listbox">
        <li v-for="(item, index) in optionList" :key="index" @click.prevent="selectOption(item)">
          <a
            href="javascript:void(0);"
            class="link_option"
            role="option"
            :aria-selected="[item.name === optionName ? 'true' : 'false']"
          >
            {{ item.name }}
          </a>
        </li>
        <li v-if="optionList.length === 0" class="list_opt_empty">데이터가 없습니다</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
export default {
  name: "CommOpt",
  mixins: [CommLayerMixin],
  props: {
    classList: [Array, Object, String],
    optionDesc: String,
    optionName: String,
    optionList: Array,
    eventName: String,
    validate: Function,
    validateMsg: {
      type: String,
      default: "",
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      option: {},
      selectedOption: {},
      displayOptionName: this.optionName,
    };
  },
  watch: {
    optionName(optionName) {
      this.displayOptionName = optionName;
    },
  },
  methods: {
    openToggle() {
      if (this.isDisabled) return;
      if (this.$refs.aria.getAttribute("aria-expanded") === "true") {
        this.$refs.aria.setAttribute("aria-expanded", "false");
        this.$refs.opt_comm.classList.remove("opt_open");
      } else {
        let optOpenArr = document.querySelectorAll(".opt_open");
        for (let i = 0, leng = optOpenArr.length; i < leng; i++) {
          optOpenArr[i].classList.remove("opt_open");
          optOpenArr[i].querySelector(".link_opt").setAttribute("aria-expanded", "false");
        }
        this.$refs.aria.setAttribute("aria-expanded", "true");
        this.$refs.opt_comm.classList.add("opt_open");
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      if (this.validate) {
        if (this.validate(option)) {
          let isDisplayConfirm = false;
          let aTagList = this.$refs.box_opt.querySelectorAll("a");
          aTagList.forEach((a, index) => {
            if (
              a.innerText === this.displayOptionName &&
              this.displayOptionName !== this.selectedOption.name
            ) {
              isDisplayConfirm = true;
            }
          });
          // TODO 공통화
          if (isDisplayConfirm) {
            const layer = {};
            layer.type = "confirm";
            layer.content = this.validateMsg;
            this._showLayer(layer, this.changeOption);
          } else {
            this.changeOption();
          }
        }
      } else {
        this.changeOption();
      }
      this.$refs.opt_comm.classList.remove("error");
    },
    changeOption() {
      this.option = this.selectedOption;
      this.displayOptionName = this.option.name;
      this.$emit(this.eventName, this.option);
    },
  },
};
</script>
