<template>
  <CalendarBase :class="{ 'is-active': isFocus }" :toolTip="toolTip">
    <el-date-picker
      v-model="input"
      :type="type"
      :placeholder="placeholder"
      :disabled="isDisabled"
      @focus="onFocus"
      @blur="onBlur"
    >
      <!-- value-format="yyyyMMdd"
      format="yyyy-MM-dd" -->
    </el-date-picker>
    <span class="ico_purchase ico_calendar" />
  </CalendarBase>
</template>

<script>
import CalendarBase from "./CalendarBase";

export default {
  name: "DatePicker",
  components: {
    CalendarBase,
  },
  props: {
    type: {
      type: String,
      default: "date",
    },
    date: Date | String,
    dateString: String,
    placeholder: {
      type: String,
      default: "날짜를 입력해주세요",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    toolTip: {
      type: String,
      default: "YYYY-MM-DD 형식으로 입력해주세요.",
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    input: {
      get() {
        return this.computedData;
      },
      set(value) {
        this.$emit("update:date", value);
        const dateString = this.moment(value).format(this.dateFormat);
        this.$emit("update:dateString", dateString, value); // 혹시 모르니 data OBJ도 넘겨준다
      },
    },
    computedData() {
      let ret = null;
      if (!this.date && !this.dateString) {
        return ret;
      }
      if (this.date) {
        //dateObj
        ret = this.date;
      } else {
        //dateString
        ret = this.dateString;
      }
      return ret;
    },
  },
  methods: {
    onFocus(e) {
      if (this.toolTip) {
        this.isFocus = true;
      }
    },
    onBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ico_purchase {
  &.ico_calendar {
    width: 10px;
    height: 11px;
    background-position: -100px -80px;
  }
}
</style>
