<template>
  <div>
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">결제정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>증빙유형<sup>*</sup></th>
            <td colspan="3">
              <div class="row_pack">
                <comm-opt
                  :classList="['type_medium']"
                  :optionDesc="'증빙유형 선택상자'"
                  :optionName="proofName"
                  :optionList="proofTypeList"
                  :eventName="'select'"
                  @select="selectProofType"
                />
                <button
                  v-if="
                    certificateItemData.proofType == 'AT' || certificateItemData.proofType == 'AB'
                  "
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickBill', certificateItemData.proofType)"
                >
                  국세청 세금계산서 불러오기
                </button>
                <button
                  v-if="certificateItemData.proofType == 'CP'"
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopCorporateCard')"
                >
                  법인카드 사용내역 불러오기
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <th>증빙일자<sup>*</sup></th>
            <td :colspan="isProofNum ? 1 : 3">
              <DatePicker
                :dateString.sync="certificateItemData.proofDate"
                @update:dateString="modifyProofDate"
              />
            </td>
            <th v-if="isProofNum">증빙번호</th>
            <td v-if="isProofNum">
              <!-- number로오는 값 string으로 변환하여 넣어 model 작업후 활성화필요 -->
              <!-- <Input
                :value.sync="certificateItemData.payment.certificateId"
                :isDisabled="true"
                :maxLength="100"
                @focusout="onFocusout"/> -->
              <Input
                :value.sync="certificateItemData.proofNum"
                :isDisabled="!isEditProofNumType"
                :maxLength="100"
                @focusout="onFocusout"
              />
            </td>
          </tr>
          <tr>
            <th v-if="!isInfra">결제수단</th>
            <td v-if="!isInfra">
              <comm-opt
                :classList="['type_medium']"
                style="width: 208px"
                :optionDesc="'결제수단 선택상자'"
                :optionName="paymentTypeName"
                :optionList="paymentTypeList"
                :eventName="'select'"
                @select="selectPaymentType"
              />
            </td>
            <th>지급예정일<sup>*</sup></th>
            <td :colspan="isInfra ? '3' : '1'">
              <DatePicker
                :dateString.sync="certificateItemData.payment.expenditureDate"
                :disabled="isAutoType"
                @update:dateString="modifyExpenditureDate"
              />
            </td>
          </tr>
          <tr>
            <th>통화코드<sup>*</sup></th>
            <td>
              <div class="row_pack">
                <comm-opt
                  :classList="['type_medium']"
                  :style="currencyName == '직접입력' ? 'width:120px' : 'width:208px'"
                  :optionDesc="'통화코드 선택상자'"
                  :optionName="currencyName"
                  :optionList="currencyDataList"
                  :eventName="'select'"
                  @select="onSelectCurrency"
                />
                <Input
                  v-if="currencyName == '직접입력'"
                  style="width: 188px"
                  :value.sync="certificateItemData.payment.currency"
                  @onInput="onInputCurrency"
                  @focusout="onFocusout"
                />
                <!-- <comm-opt
                  :classList="['type_medium']"
                  :style="currencyName == '직접입력' ? 'width:120px' : 'width:208px' "
                  :optionDesc="'결제수단 선택상자'"
                  :optionName="currencyName"
                  :optionList="currencyDataList"
                  :eventName="'select'"
                  @select="(obj) => certificateItemData.payment.currency = obj.code"
                /> -->
              </div>
            </td>
            <!-- <th>지급예정일 수정사유<span v-if="requiredOption" class="ico_purchase ico_star"></span></th> -->
            <th>지급예정일 수정사유</th>
            <td>
              <Input
                placeholder="수정사유를 입력하세요"
                :value.sync="certificateItemData.payment.modReason"
                :maxLength="100"
                @focusout="onFocusout"
              />
            </td>
          </tr>
          <tr>
            <th>공급가액<sup>*</sup></th>
            <td>
              <!-- number로오는 값 string으로 변환하여 넣어 model 작업후 활성화필요 -->
              <InputAutoMoney
                :value.sync="certificateItemData.payment.supplyPrice"
                placeholder="공급가액을 입력하세요"
                :maxLength="100"
                @onInput="onInputSupply"
                @focusout="onFocusout"
              />
              <!-- :allowString="false"
                @onInput="onInput"
                @keyup="onSupplyPriceChange" -->
              <!-- <InputAutoMoney
                placeholder="공급가액을 입력하세요"
                :value.sync="certificateItemData.payment.supplyPrice"
                :maxLength="100"
                @focus="onFocusout"/> -->
            </td>
            <th>세액</th>
            <td>
              <!-- number로오는 값 string으로 변환하여 넣어 model 작업후 활성화필요 -->
              <!-- <Input
                :value.sync="certificateItemData.payment.taxAmount"
                :maxLength="100"
                @focusout="onFocusout"/> -->
              <InputAutoMoney
                v-if="isEditTaxType"
                :value.sync="certificateItemData.payment.taxAmount"
                placeholder="세액을 입력하세요"
                :maxLength="100"
                @onInput="onInputTax"
                @focusout="onFocusout"
              />
              <template v-else>
                {{ certificateItemData.payment.taxAmount || "-" }}
              </template>
              <!-- {{ certificateItemData.payment.taxAmount | currency }} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총금액<span v-if="isTaxType" class="txt_info">(공급가액+세액)</span></dt>
          <dd>
            {{ totalAmount | currency
            }}<span class="util_total">{{ certificateItemData.payment.currency }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import InputNumber from "@/_approval/components/common/input/InputNumber";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Input from "@/_approval/components/common/input/Input";
// import InputMoney from '@/_approval/components/common/input/InputMoney';
// import StatementPaymentListLine from '@/_approval/components/statement/write/StatementPaymentListLine';

//import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';
import { addCommaForMoney, getMoneyToNumber, removeComma } from "@/utils/stringUtils";

import DatePicker from "@/components/common/calendar/DatePicker";

import {
  stringToDateWithTime,
  getFormatData,
  getLastDayOfCurrentMonth,
  getYearMonth,
  getDayToStringWithoutTime,
  getLastDayOfNextMonth,
} from "@/utils/dateUtils";
import ApiService from "@/services/ApiService";
import { getCanConvertNumber } from "@/utils/numberUtils";
import WithHoldingTaxModel from "@/_approval/components/statement/write/WithHoldingTaxModel";

export default {
  name: "StatementPayment",
  components: {
    CommOpt,
    InputNumber,
    Input,
    InputAutoMoney,
    // InputMoney
    DatePicker,
  },
  // /mixins: [CommFlatpickrMixin],
  props: {
    certificateItemData: {
      type: Object,
      default() {
        return {};
      },
    },
    isInfra: Boolean,
  },
  data() {
    return {
      // 증빙유형
      proofTypeList: [
        { code: "AT", name: "세금계산서(자동)" },
        { code: "TB", name: "세금계산서(수동)" },
        { code: "PT", name: "세금계산서(종이)" },
        { code: "AB", name: "계산서(자동)" },
        { code: "BL", name: "계산서(수동)" },
        { code: "CP", name: "법인카드(개인)" },
        { code: "ID", name: "개인카드" },
        { code: "PB", name: "계산서(종이)" },
        { code: "RC", name: "영수증" },
        { code: "WT", name: "원천세" },
        { code: "NN", name: "증빙없음" },
      ],
      paymentTypeList: [
        { code: "AT", name: "계좌이체(원화)" },
        { code: "AD", name: "자동이체" },
        { code: "GR", name: "지로" },
        { code: "CD", name: "사내기부금" },
        { code: "DA", name: "자동이체(동이의결)" },
        { code: "NN", name: "증빙없음" },
      ],
      currencyDataList: [
        // { code:'KRW', name:'KRW' },
        // { code:'USD', name:'USD' },
        // { code:'EUR', name:'EUR' },
        // { code:'JPY', name:'JPY' },
        // { code:'CNY', name:'CNY' },
        // { code:'', name:'직접입력' },
      ],
      expenditureDate: "",
      // paymentWayList: [], // 결제수단
      // paymentWay: {
      //   code: '',
      //   name: '결제수단 선택',
      // },
      // paymentDate:'', // 지급예정일
      // editReason:'', // 수정사유

      // currentList: [], // 통화코드
      // current: {
      //   code: '',
      //   name: 'KRW',
      // },
      // price: '', // 공급가액
      // tax: '', // 세액
      // totalPrice: '', // 총검수금액
    };
  },
  computed: {
    isAutoType() {
      return (
        this.certificateItemData.proofType == "AT" || this.certificateItemData.proofType == "AB"
      );
    },
    // 증빙일자 자동 - 활성화
    isAutoProofDateType() {
      const proofType = this.certificateItemData.proofType;
      return proofType == "AT" || proofType == "AB";
    },
    // 증빙번호 적용
    isProofNum() {
      const proofType = this.certificateItemData.proofType;
      return (
        proofType == "AT" ||
        proofType == "TB" ||
        proofType == "AB" ||
        proofType == "BL" ||
        proofType == "CP"
      );
    },
    // 증빙번호 수정 - 활성화
    isEditProofNumType() {
      const proofType = this.certificateItemData.proofType;
      return proofType == "TB" || proofType == "BL";
    },
    // 공급가액 자동 - 활성화
    isAutoSupplyPrice() {
      const proofType = this.certificateItemData.proofType;
      return proofType == "AT" || proofType == "AB" || proofType == "CP";
    },
    // 세액 적용 여부
    isTaxType() {
      const proofType = this.certificateItemData.proofType;
      return proofType != "WT" && proofType != "NN";
    },
    // 세액 활성화
    isEditTaxType() {
      const proofType = this.certificateItemData.proofType;
      return proofType == "AT" || proofType == "TB" || proofType == "PT" || proofType == "CP";
    },
    paymentTypeName() {
      if (this.certificateItemData.payment.paymentType == "") {
        return "결제수단을 선택해주세요";
      }
      return this.paymentTypeList.find(
        (item) => item.code == this.certificateItemData.payment.paymentType,
      ).name;
    },
    currencyName() {
      const correctItem = this.currencyDataList.find(
        (item) => item.code == this.certificateItemData.payment.currency,
      );
      const currency = correctItem ? correctItem.name : "원화";
      this.paymentTypeList.find((item) => item.code == "AT").name = "계좌이체(" + currency + ")";
      return correctItem ? correctItem.name : "직접입력";
    },
    proofName() {
      if (this.certificateItemData.proofName == "") {
        this.certificateItemData.proofType = "AT";
        this.certificateItemData.proofName = "세금계산서(자동)";
      }
      return this.proofTypeList.find((item) => item.code == this.certificateItemData.proofType)
        .name;
    },
    // 세액 자동계산 타입별 구분
    getTaxRateWithProofType() {
      switch (this.certificateItemData.proofType) {
        case "AT": // 세금계산서(자동)
        case "TB": // 세금계산서(수동)
        case "PT": // 세금계산서(종이)
        case "CP": // 법인카드 (개인)
        case "ID": // 개인카드
          return 10;
        case "AB": // 계산서(자동)
        case "BL": // 계산서(수동)
        case "PB": // 계산서(종이)
        case "RC": // 영수증
          return 0;
        default:
          // 원천세, 증빙없음
          return null;
      }
    },
    totalAmount() {
      const { taxAmount, supplyPrice } = this.certificateItemData.payment;
      let total = String(getMoneyToNumber(taxAmount) + getMoneyToNumber(supplyPrice));
      this.certificateItemData.payment.totalAmount = total;
      return total || "0";
    },
  },
  watch: {
    isInfra: {
      immediate: false,
      handler() {
        this.setExpenditureDate();

        this.getCurrencyData();
      },
    },
  },
  mounted() {
    // 승인할 문서 상세에서 지급예정일 빈값으로 노출되는 이슈처리로 주석처리
    if (!this.$route.meta.sideMenuId && this.$route.name == "StatementWrite") {
      this.setExpenditureDate();
    }

    this.getCurrencyData();
  },
  methods: {
    // 통화코드 입력시
    onInputCurrency() {
      this.certificateItemData.voucher.currency = this.certificateItemData.payment.currency;
    },
    // 공급가액 입력시
    onInputSupply() {
      this.setTax(); // 세액 자동
      this.setDebitCredit(); // 차변대변 자동
    },
    // 세액 입력시
    onInputTax() {
      this.setDebitCredit(); // 차변대변 자동
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    // 통화코드 선택시
    onSelectCurrency(obj) {
      this.certificateItemData.payment.currency = obj.code;
      this.certificateItemData.voucher.currency = obj.code;

      // 전표일자가 존재한다면 환율 조회 API 호출
      this.getExchangeInfo();
    },
    // 증빙유형 선택
    selectProofType(obj) {
      // 증빙유형 데이터 적용
      this.certificateItemData.proofType = obj.code;
      this.certificateItemData.proofName = obj.name;

      this.setDataReset(); // 작성한 데이터 리셋
      this.setExpenditureDate(); // 지급예정일 자동
      this.setTax(); // 세액 자동
      this.setDebitCredit(); // 차변대변 자동
    },
    // 결재수단 선택시
    selectPaymentType(obj) {
      this.certificateItemData.payment.paymentType = obj.code;
    },
    // 증빙유형 변경시 리셋
    setDataReset() {
      this.certificateItemData.proofDate = null; // 증빙일자
      this.certificateItemData.proofNum = ""; // 증빙번호
      this.certificateItemData.payment.expenditureDate = ""; // 지급예정일
      this.certificateItemData.payment.supplyPrice = ""; // 공급가액
      this.certificateItemData.businessRegFile = {}; // 사업자/주민등록번호 사본
      this.certificateItemData.bankBookFile = {}; // 통장사본
      this.certificateItemData.userNum = null; // 사용자 번호
      this.certificateItemData.corporateNum = ""; // 사업자/주민등록번호
      this.certificateItemData.partnerName = ""; // 지급처명
      this.certificateItemData.voucher.erpPartnerName = ""; // 지급처명
      this.certificateItemData.voucher.erpPartnerCode = ""; // 지급처 코드
      this.certificateItemData.voucher.voucherDate = ""; // 전표>전표일자
      this.certificateItemData.voucher.proofDate = ""; // 전표>증빙일자
      if (this.certificateItemData.proofType != "WT") {
        // 원천세 아닐경우
        this.certificateItemData.voucher.withholdingTax = {}; // 원천세
      } else {
        this.certificateItemData.voucher.withholdingTax = new WithHoldingTaxModel(); // 원천세
      }
    },
    // 지급예정일 자동
    setExpenditureDate() {
      this.expenditureDate = getLastDayOfNextMonth();
      if (this.expenditureDate.getDay() == 0 || this.expenditureDate.getDay() == 6) {
        this.getExpenditureDate(this.expenditureDate);
        return;
      }
      this.expenditureDate = getFormatData(this.expenditureDate);
      this.certificateItemData.payment.expenditureDate = this.expenditureDate;
      this.certificateItemData.voucher.expenditureDate = this.expenditureDate;
    },
    // 세액 자동
    setTax() {
      const paymentData = this.certificateItemData.payment;
      const taxRate = this.getTaxRateWithProofType;
      let supplyPrice = getMoneyToNumber(paymentData.supplyPrice);
      // supplyPrice = supplyPrice.indexOf(',') > -1 ? supplyPrice.replaceAll(',','') : supplyPrice;

      if (taxRate == 10) {
        const tax = Math.round(supplyPrice * (10 / 100));
        paymentData.taxAmount = tax != 0 ? String(addCommaForMoney(tax)) : "";
        // paymentData.supplyPrice = ( supplyPrice == '' ) ? '' : addCommaForMoney(supplyPrice);
      } else if (taxRate == 0) {
        paymentData.taxAmount = "0";
      } else {
        paymentData.taxAmount = "";
      }
    },
    // 차변대변 자동
    setDebitCredit() {
      if (this.isInfra) return;
      const supplyPrice = this.certificateItemData.payment.supplyPrice;
      const taxAmount = this.certificateItemData.payment.taxAmount;
      const voucherItems = this.certificateItemData.voucher.voucherItems;
      // 차변
      const debitRow = voucherItems.filter(
        (item) => (item.crdr === "DR" || item.debit !== "") && item.surtaxYn == false,
      );
      // 차변 부가세대급금 항목
      const debitRowSurtax = voucherItems.filter(
        (item) => (item.crdr === "DR" || item.debit !== "") && item.surtaxYn == true,
      );
      // 대변
      const creditRow = voucherItems.filter((item) => item.crdr === "CR" || item.credit !== "");

      if (debitRow.length == 0) return;
      // 차변이 추가된경우 (차변이 2개 이상인 경우는 적용하지 않습니다) - 초기화
      if (debitRow.length > 1) {
        debitRow.forEach((item) => {
          item.debit = "";
          item.suplyPriceFlag = true;
        });
      } else {
        debitRow[0].debit = supplyPrice;
        debitRow[0].suplyPriceFlag = true;
      }
      // 부가세대급금 항목이 있을경우
      if (debitRowSurtax.length > 1) {
        console.log(debitRowSurtax);
        debitRowSurtax[0].debit = taxAmount;
      }
      creditRow[0].credit = this.totalAmount;
    },
    // 증빙일자 수정시 전표정보>전표일자, 증빙일자에 동일 적용
    modifyProofDate(dateString) {
      if (!this.isAutoProofDateType) return;
      this.certificateItemData.voucher.voucherDate = dateString;
      this.certificateItemData.voucher.proofDate = dateString;
    },
    // 지급예정일 수정시
    modifyExpenditureDate(dateString) {
      //지급예정일 국세청세금계산서/계산서, 법인카드 선택시 자동 입력
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      // if(!this.isAutoProofDateType) return;
      this.certificateItemData.voucher.expenditureDate = dateString;
      if (this.expenditureDate !== dateString) {
        this.certificateItemData.payment.modifiedFlag = true;
        //this.expenditureDate = dStr;
      }
    },
    async getExpenditureDate(day) {
      const dayLabel = getDayToStringWithoutTime(day);
      // 지급예정일 after api 호출
      const path = `${this.$apiPath.WORKING_DAY}?date=${dayLabel}`;
      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.certificateItemData.payment.expenditureDate = res.data.workingDate;
      }
    },
    getValidText(strOrg) {
      if (!strOrg) return "";

      strOrg = strOrg.trim();

      // 그냥 다 숫자면 그대로 리턴.
      if (getCanConvertNumber(strOrg)) {
        return strOrg;
      }

      let ret = "";

      let char;

      for (var i = 0; i < strOrg.length; ++i) {
        char = strOrg.charAt(i);

        if (!getCanConvertNumber(char)) continue;

        ret += char;
      }

      return ret;
    },
    async getCurrencyData() {
      const path = `${this.$apiPath.CURRENCY}`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.currencyDataList = res.data;

        // this.currencyDataList.push({ code: '', name: '직접입력' });
      }
    },
    async getExchangeInfo() {
      let exchangeDate = this.certificateItemData.voucher.voucherDate
        ? this.certificateItemData.voucher.voucherDate.replaceAll("-", "")
        : "";
      if (exchangeDate == "") return;

      const fromCurrency = this.certificateItemData.payment.currency;

      const path = `${this.$apiPath.VOUCHER_EXCHANGE_RATE}?exchangeDate=${exchangeDate}&exchangeType=S&fromCurrency=${fromCurrency}&toCurrency=KRW`;
      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.certificateItemData.voucher.currency = res.data.currency;
        this.certificateItemData.voucher.exchangeRateDate = res.data.date;
        this.certificateItemData.voucher.exchangeRate = res.data.exchangeRate;
      }
    },
  },
};
</script>
